import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
//import DiscomForm from "components/forms/SimpleContactUs.js";
import NetMeteringForm from "components/forms/NetMeteringForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { Helmet } from "react-helmet";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Net Metering Application</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Working with best brands like Adani, Waree, Havells, Polycab, Vikram Solar and other brands for all kinds of Solar systems. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/NetMeteringToolPage" />
            </Helmet>
      <Header>
      </Header>
            <NetMeteringForm />
      <ContactDetails
        cards={[
          {
            title: "Udaipur",
            description: (
              <>
                <Address>
                  <AddressLine>Kanak Complex,</AddressLine>
                  <AddressLine>Opp. Mangal Murti Plaza</AddressLine>
                  <AddressLine>Eklingpura, Udaipur</AddressLine>
                  <AddressLine>Udaipur -313003</AddressLine>
                  <AddressLine>Rajasthan, India</AddressLine>
                </Address>
                <Email>info@usplsolar.com</Email>
                <Phone>+91 82095 80212</Phone>
              </>
            )
          }    
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
