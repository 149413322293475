import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGridUSPL.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import { Helmet } from "react-helmet";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Best Solar Company in Udaipur, Rajasthan</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Best brands like Adani, Waree, Vikram Solar, UTL, Luminous, Growatt, Renewsys. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/AboutUsUSPLPage" />
            </Helmet>
      <Header>
      </Header>
      <MainFeature1
        subheading={<Subheading>About USPL Solar</Subheading>}
        heading="GREEN ENERGY FOR EVERYONE "
        description="USPL, FOUNDED IN 2016, IS AN ENTERPRISE WITH A HEART FOR ENERGY CONSERVATION AND ENVIRONMENTAL PROTECTION.
        Our objective is to delight our customers, employees and the communities we serve. Our systems symbolize performance, quality and reliability.
        
        We enforce our workforce and our suppliers to maintain the highest quality standards worthy of our clients’ trust.
        
        From research to development and sales to service, USPL aims to be the leader in PV industry with a razor-sharp vision and outlook for sustainable development."
        buttonRounded={false}
        primaryButtonText="See Ethos"
        imageSrc="https://images.unsplash.com/photo-1641290126851-610704d11b23?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="SAVE MONEY, SAVE THE ENVIRONMENT"
        description="To enable access to ecological and economical electricity by bringing unconventional sources of energy to the common man."
        buttonRounded={false}
        primaryButtonText="Enquire"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Mission</Subheading>}
        heading="We strive"
        description="To create communities that are self-reliant for their energy needs and can focus on their socio-economic growth rather than getting access to basic needs."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "A robust software and a strong technical team helps us provide round the clock support to our customers. "
          },
          {
            imageSrc: ShieldIconImage,
            title: "Backed by world-class manufacturers 7 experience",
            description: "Our systems are backed by leading manufacturers around the globe that provides us with latest technology and our 20+ years of experience in the industry helps us save time and effort for customers."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Customers love us for the seamless experience we provide from ownership to installation and regulatory compliances. "
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
