import { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"

import { GoogleSpreadsheet } from 'google-spreadsheet'
//import { mockComponent } from "react-dom/test-utils";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-300 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-300 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const ThreeColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
//const Input = tw.input``;
//const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const Input = ({ name, id, label, doChange, placeholder, type}) => {
  return (
    <label htmlFor={name} className="form-label">
      { label }
      <input type={type} id={id} name={name} required placeholder={placeholder} onChange={doChange} />
    </label>
  )
}
const Number = ({ name, id, label, doChange, type, placeholder, minLength, maxLength}) => {
  return (
    <label htmlFor={name} className="form-label">
      { label }
      <input type={type} id={id} name={name} placeholder={placeholder} minLength={minLength} maxLength={maxLength} required onChange={doChange} />
    </label>
  )
}

function NetMeteringForm() {
  const [formData, setFormData] = useState({})

  //Import environmental varialbles
  const {
    REACT_APP_PRIVATE_KEY,
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_SPREADSHEET_ID,
    REACT_APP_SHEET_ID
  } = process.env

  //creating new object of google spreadsheet
  const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);

  //Function append spreadsheet to add row into google sheet

  const appendSpreadsheet = async (row) => {
    console.log(REACT_APP_SHEET_ID);
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doc.loadInfo();
      console.log(doc.loadInfo());
      const sheet = doc.sheetsById[REACT_APP_SHEET_ID];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  //end

  const handleChange = (event) =>{
    setFormData({...formData,[event.target.name]:event.target.value})
  }

  const handleSubmit = (event) =>{
    event.preventDefault()
    appendSpreadsheet(formData)
    event.target.reset();
    alert("You will receive the Documents shortly on \nthe email you mentioned in the form! \n Download, Print and Submit to your DISCOM office!");
  }
  return (
      <Container>
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h2>Receive DISCOM approval</h2>
              <form className="NetMeteringForm" onSubmit={handleSubmit} >
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="cname">Consumer Name</Label>
                      <Input type="text" name="cname" required placeholder="E.g. John Doe" doChange={handleChange} />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email">Your Email Address</Label>
                      <Input type="email" name="email" required placeholder="E.g. john@mail.com" doChange={handleChange}/>
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="mobile">Mobile</Label>
                      <Number type="tel" name="mobile" minLength={10} maxLength={10} required placeholder="Consumer Mobile Number" doChange={handleChange}/>
                    </InputContainer>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="address">Address</Label>
                      <Input type="textarea" name="address" placeholder="Address of Consumer" required doChange={handleChange}/>
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="knumber">K Number</Label>
                      <Number type="tel" name="knumber" minLength={12} maxLength={12} required placeholder="E.g. 13011xxxxxxxx" doChange={handleChange} />
                    </InputContainer>
                  </Column>
                  <Column>
                  <ThreeColumn>
                      <Column>
                    <InputContainer>
                    <Label htmlFor="category">Category</Label>
                      <select name="category" required placeholder="Category" onChange={handleChange}>
                      <option defaultValue="Select Category">Select Category</option>
                      <option value="Domestic">Domestic</option>
                      <option value="NDS">NDS</option>
                      <option value="Industrial">Industrial</option>
                      </select>               
                    </InputContainer>
                    </Column>
                    <Column>
                    <InputContainer>
                    <Label htmlFor="phase">Phase</Label>
                      <select name="phase" required placeholder="Phase" onChange={handleChange}>
                      <option defaultValue="Select Phase">Select Phase</option>
                      <option value="1 Phase">1 Phase</option>
                      <option value="3 Phase">3 Phase</option>
                      <option value="LT/CT">LT/CT</option>
                      <option value="HT/CT">HT/CT</option>
                      </select>
                    </InputContainer>
                    </Column>
                    <Column>
                    <InputContainer>
                    <Label htmlFor="discom">Discom</Label>
                      <select name="discom" required placeholder="Discom" onChange={handleChange}>
                      <option defaultValue="Select DISCOM">Select DISCOM</option>
                      <option value="AVVNL">AVVNL</option>
                      <option value="JdVVNL">JdVVNL</option>
                      <option value="JVVNL">JVVNL</option>
                      </select>
                    </InputContainer>
                    </Column>
                    </ThreeColumn>
                    <InputContainer>
                      <Label htmlFor="office">DISCOM Office</Label>
                      <Input type="text" name="office" required placeholder="Name of DISCOM office" doChange={handleChange} />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="sload">Sanctioned Load</Label>
                      <Input type="tel" name="sload" required placeholder="E.g. 4" doChange={handleChange}/>
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="capacity">Solar Capacity</Label>
                      <Input type="tel" name="capacity" required placeholder="E.g. 8" doChange={handleChange}/>
                    </InputContainer>
                    <InputContainer>
                      <div class="flex items-start mb-6">
                      <div class="flex items-left h-10  ">
                      <Input id="remember" type="checkbox" value="" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required/>
                      </div>
                      <Label htmlFor="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400">I agree with the <a href="/components/innerPages/TermsOfServicePageUSPL" class="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</Label>
                      </div>
                      </InputContainer>
                  </Column>
                </TwoColumn>
                <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
              </form>
            </div>
            <SvgDotPattern1 />
          </FormContainer>
        </Content>
      </Container>
    );
}
export default NetMeteringForm;
