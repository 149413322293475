import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Check out other products",
  tabs = {
    Cleaning: [
      {
        imageSrc:
          "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0B5LJ7JD9&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Wiper",
        content: "Solar Panel cleaning Wiper",
        //price: "$5.99",
        rating: "5.0",
        reviews: "87",
        url: "https://amzn.to/3S4PxSK"
      },
      {
        imageSrc:
          "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B095YW7QFZ&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Rotatable Wiper",
        content: "Dual Wiper",
        //price: "$2.99",
        rating: "4.8",
        reviews: "32",
        url: "https://amzn.to/3Uxn8X3"
      },
      {
        imageSrc:
          "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07MLQQCZQ&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Double Wipers",
        content: "Set of Two",
        //price: "$7.99",
        rating: "4.9",
        reviews: "89",
        url: "https://amzn.to/3StDLRH"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09SWNTJNL&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Portable Washer",
        content: "With double batteries.",
        //price: "$8.99",
        rating: "4.6",
        reviews: "12",
        url: "https://amzn.to/3BHIZ5G"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09DQ4MWHW&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Portable Washer",
        content: "5m Hose",
        //price: "$7.99",
        rating: "4.2",
        reviews: "19",
        url: "https://amzn.to/3C2Cwn4"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08R69T6NL&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Power Washer",
        content: "1500W of powerful cleaning.",
        //price: "$2.99",
        rating: "5.0",
        reviews: "61",
        url: "https://amzn.to/3C5BoPO"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07P5YD7KM&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Hose Extension",
        content: "20 Meter Hose",
        //price: "$3.99",
        rating: "4.2",
        reviews: "95",
        url: "https://amzn.to/3C1ZDyf"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07G9MPY1X&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Compact Power Washer",
        content: "Small form factor, powerful cleaning!",
        //price: "$3.99",
        rating: "3.9",
        reviews: "26",
        url: "https://amzn.to/3LDFi5q"
      }
    ],
    Decor: [
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08T6KP58H&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Blossom Flower Lights",
        content: "Flower string lights.",
        //price: "$5.99",
        rating: "5.0",
        reviews: "87",
        url: "https://amzn.to/3BJGQGJ"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P5PBKB8&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Fairy Lights",
        content: "79 Feet. Warm White",
        //price: "$2.99",
        rating: "4.8",
        reviews: "32",
        url: "https://amzn.to/3R7I83R"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09X5NNKSF&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Wall lights",
        content: "Set of Two",
        //price: "$7.99",
        rating: "4.9",
        reviews: "89",
        url: "https://amzn.to/3BFuyyX"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084ZNM4PM&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Fairy Lights",
        content: "39 Feet. Warm White.",
        //price: "$8.99",
        rating: "4.6",
        reviews: "12",
        url: "https://amzn.to/3dBhPWa"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07DPVN9ZD&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Crystal Balls",
        content: "30 bulbs. Warm White.",
        //price: "$7.99",
        rating: "4.2",
        reviews: "19",
        url: "https://amzn.to/3SKSGY3"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08SPY9Y2L&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Lantern",
        content: "30 LEDs. Warm White.",
        //price: "$2.99",
        rating: "5.0",
        reviews: "61",
        url: "https://amzn.to/3DOKCkI"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07ZWH8JDH&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Flickering Lantern",
        content: "Amber LED",
        //price: "$3.99",
        rating: "4.2",
        reviews: "95",
        url: "https://amzn.to/3r3fW7v"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08T792JHZ&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Flickering Flame",
        content: "99 LEDs. Warm Yellow.",
        //price: "$3.99",
        rating: "3.9",
        reviews: "26",
        url: "https://amzn.to/3f3gCaf"
      }
    ],
    Garden: [
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081LDL2ZM&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Path Light",
        content: "Color Changing Disc.",
        //price: "$5.99",
        rating: "5.0",
        reviews: "87",
        url: "https://amzn.to/3SbN6xG"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08QR5WY2R&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Solar Fountain",
        content: "Floating. Fully Automatic.",
        //price: "$2.99",
        rating: "4.8",
        reviews: "32",
        url: "https://amzn.to/3DWYA3M"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0B6SD44WJ&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Dragonfly Lights",
        content: "Color Changing. 7 Colors.",
        //price: "$7.99",
        rating: "4.9",
        reviews: "89",
        url: "https://amzn.to/3StDLRH"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0B9YJVCSY&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Night Spotlight",
        content: "Motion sensing. Set of 2. ",
        //price: "$8.99",
        rating: "4.6",
        reviews: "12",
        url: "https://amzn.to/3r02ZLM"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08ZNH95H6&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Path Lights",
        content: "White. Pack of 4.",
        //price: "$7.99",
        rating: "4.2",
        reviews: "19",
        url: "https://amzn.to/3C5aU0H"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07S63LDGW&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Garden Torch",
        content: "Set of 2. Fire effect.",
        //price: "$2.99",
        rating: "5.0",
        reviews: "61",
        url: "https://amzn.to/3dHAOhx"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07J5R1HCZ&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Garden Torch",
        content: "Fire effect. Landscaping light.",
        //price: "$3.99",
        rating: "4.2",
        reviews: "95",
        url: "https://amzn.to/3DNceGG"
      },
      {
        imageSrc:
        "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081YQZ955&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
        title: "Flood Light",
        content: "10W. Auto On/Off.",
        //price: "$3.99",
        rating: "3.9",
        reviews: "26",
        url: "https://amzn.to/3BZTkKv"
      }
    ],
    Travel: getRandomCards()
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Buy Now</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0BF5HV12H&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Flashlight",
      content: "Multipurpose.",
      //price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "https://amzn.to/3UKUpyi"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09B6128V2&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Lantern",
      content: "With MObile Charger.",
      //price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "https://amzn.to/3LCZ2Ge"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09651PMXJ&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Power Bank",
      content: "20000mAh. With Flashlight.",
      //price: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "https://amzn.to/3S81WVX"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B085KVK22P&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Foldable Solar Panel",
      content: "Charge your devices using USB.",
      //price: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "https://amzn.to/3LB6KAZ"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081GCC657&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Solar Backpack",
      content: "10 Watt. 20 Litres.",
      //price: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "https://amzn.to/3LB7dmJ"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081GCH424&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Solar Camera Bag",
      content: "24 Watt charger.",
      //price: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "https://amzn.to/3C1prKN"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0821H5T93&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Camping Light",
      content: "30 Watt. USB.",
      //price: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "https://amzn.to/3xQ7Sed"
    },
    {
      imageSrc:
      "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0B1V1LFWM&Format=_SL160_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&auto=format&fit=crop&w=500&q=80",
      title: "Emergency Light",
      content: "With Radio & Bluetooth.",
      //price: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "https://amzn.to/3Sw4yNp"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
