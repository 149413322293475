import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//import { css } from "styled-components/macro"; //eslint-disable-line
//import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
//import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
//import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";


//const Heading = tw(SectionHeading)``;
//const Subheading = tw(SubheadingBase)`text-center mb-3`;
//const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.a`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 transition-transform duration-300 hover:cursor-pointer transform hover:scale-105  `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none text-primary-300`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;
export default ( {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Domestic Solar",
      description: "Save upto 100% on your electricity bills with best quality components with single day installation and quick DISCOM approvals for residences.",
      url: "DomesticPage"
    },
    {
      imageSrc: SupportIconImage, 
      title: "Commercial Solar",
    description: "Save upto 70% on your electricity bills with our specially designed sytems for commercial users.",
      url: "CommercialPage"
    },
    { 
      imageSrc: CustomizeIconImage, 
      title: "Industrial Solar",
    description: "Lock your energy prices today for the next 25 years. No more worrying about rising electricity expenses and its impact on your business.",
    url: "IndustrialPage"
  },
    {
      imageSrc: ReliableIconImage, 
      title: "Agricultural Solar",
    description: "Fulfill your water demand and energy demand by our specially curated power plants that provide water during watering periods and income during non-watering periods.",
    url: "AgriculturalPage"
  },
    { 
      imageSrc: FastIconImage, 
      title: "Services",
    description: "Design, Installation, Operation & Maintenance or Liasing, we provide services from the entire cycle of going Solar. ",
    url: "ServicesPage"
  },
    {
       imageSrc: SimpleIconImage, 
      title: "DISCOM Tool",
    description: "Tool to let you prepare net-metering application and get faster approvals for your solar power plant.",
    url: "NetMeteringToolPage"
  }
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
 
}) => {
  return (
    <Container>
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="Best Solar Services in Rajasthan" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
}