import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/StoreCardGrid.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import { Helmet } from "react-helmet";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  //const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Solar Lights, Solar appliances and Solar Products</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Working with best brands like Adani, Waree, Havells, Polycab, Vikram Solar and other brands for all kinds of Solar systems and Best Solar Products. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/StorePage" />
            </Helmet>
      <Hero
        heading={<>Cleaning & Maintenance <HighlightedText>Products</HighlightedText></>}
        description="Solar Power Plants work best with proper cleaning and maintenance. Get your cleaning gear to increase your savings by 30%."
        imageSrc="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0B88F8Q4J&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&fit=crop&w=768&q=80"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Buy Now"
        primaryButtonUrl="https://amzn.to/3LGNB0A "
      />
      <MainFeature
        subheading={<Subheading>Industrial Plants</Subheading>}
        heading={
          <>
            Want to get an industrial solution?
            <wbr /> <HighlightedText>We've got your back!</HighlightedText>
          </>
        }
        description={
          <Description>
            Industrial Solar Power Plants require more manpower and time to clean and maintain. We have the best solution for your industrial needs.
            <br />
            <br />
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Buy Now"
        primaryButtonUrl="https://amzn.to/3SqULbh"
        imageSrc={
          "//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08GG7HNCV&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=a21tjruun40e6-21&language=en_IN&fit=crop&w=768&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Check out other <HighlightedText>products.</HighlightedText>
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
}
