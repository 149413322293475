import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDarkCosmic.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Cosmic eMobility</Subheading>}
        heading="We are a Green company. "
        description="With sustainability in our minds 24/7. We strive to create our whole cycle such that it is carbon offsetting. Our products are made of recycled materials and our products are against the idea of obsolescence."
        buttonRounded={false}
        primaryButtonText="See Whitepaper"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        description="Our software and hardware are scalable and can be used by Fleet Owners, Parking Owners and End-Consumers alike. "
        buttonRounded={false}
        primaryButtonText="Invest"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="We believe that products should not become oblsolete with time and should have longer lives to achieve true sustainability. And it only adds cherry if you can make some money out of your investments. "
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "A robust software and a strong technical team helps us provide round the clock support to our customers. "
          },
          {
            imageSrc: ShieldIconImage,
            title: "Backed by world-class developers",
            description: "Our products are backed by leading developers around the globe that provides our products with a rigid back-bone. "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Customers love us for the seamless experience we provide from ownership to renting out the EVSEs. "
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
