import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-DPQFGERNYY"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID);


const RouteChangeTracker = ({ history }) => {

  history.listen((location, action) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
  });
}


Modal.setAppElement("#root");

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

export default withRouter(RouteChangeTracker);