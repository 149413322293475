import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ProductFeatures.js";
import { Helmet } from "react-helmet";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Solar Systems for Homes, Farms and Shops</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Working with best brands like Adani, Waree, Havells, Polycab, Vikram Solar and other brands for Domestic Solar systems. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/DomesticPage" />
            </Helmet>
      <Header>
      </Header>
      <MainFeature1
        subheading={<Subheading>GREEN ENERGY FOR EVERYONE</Subheading>}
        heading="Domestic Solar Power Plants"
        description="We provide fast, secure and reliable installations for Homes. We take care of everything from design to installation to approvals. "
        buttonRounded={false}
        primaryButtonText="Send Bill"
        imageSrc="https://scontent.famd8-1.fna.fbcdn.net/v/t31.18172-8/28954278_962947753830256_1652481584782718916_o.jpg?_nc_cat=104&ccb=1-5&_nc_ohc=aUXAqQa3e74AX8MOtih&_nc_ht=scontent.famd8-1.fna&oh=cd39c9e066b0938feca0835bfd378622&oe=61405D7A"
      />
      <MainFeature1
        subheading={<Subheading>BEST PRODUCTS</Subheading>}
        heading="That give you the most value for your money"
        description="Our products and installation are best in class. Our turnaround times are among the fastest in the industry. All this help us save upto 20% on your Solar Power Plant."
        buttonRounded={false}
        primaryButtonText="Enquire"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>STEPS TO GO SOLAR</Subheading>}
        heading="Easy"
        description="We’ll talk to understand your requirements and create a quote free of cost based on your existing and predicted energy consumption."
        />
      <Footer />
    </AnimationRevealPage>
  );
};
