import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ProductFeatures.js";
import { Helmet } from "react-helmet";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Solar Power Plants for Factories and Industries</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Working with best brands like Adani, Waree, Havells, Polycab, Vikram Solar and other brands for Industrial Solar systems from 50kW upto 1MW. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/IndustrialPage" />
            </Helmet>
      <Header>
      </Header>
            <MainFeature1
        subheading={<Subheading>Green and Cost Effective Electricity for Industries</Subheading>}
        heading="Save your Operating Costs "
        description="We provide various models for Industries to adopt Solar Energy. From CAPEX to Co-Owned, our offers will help you save upto 70% on your electricity costs and lock your energy prices to save ou from unexpected costs in the future."
        buttonRounded={false}
        primaryButtonText="Ask for Quote!"
        imageSrc="https://scontent.famd8-1.fna.fbcdn.net/v/t31.18172-8/28954278_962947753830256_1652481584782718916_o.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=aUXAqQa3e74AX8MOtih&_nc_ht=scontent.famd8-1.fna&oh=cd39c9e066b0938feca0835bfd378622&oe=61405D7A"
      />
      <MainFeature1
        subheading={<Subheading>Our Offers</Subheading>}
        heading="Fully-owned, partly-owned, RESCO."
        description="Our offers are always considerate of the investment capabilities of our customers and we make sure we provide them with the best possible solutions so as to not hinder the adoption of solar energy. We also provide industry-specific solutions that help you maximise energy efficiency adding to your savings."
        buttonRounded={false}
        primaryButtonText="Enquire"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>STEPS TO GO SOLAR</Subheading>}
        heading="Easy"
        description="We’ll talk to understand your requirements and create a quote free of cost based on your existing and predicted energy consumption."
      />
      <Footer />
    </AnimationRevealPage>
  );
};
