import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import { Helmet } from "react-helmet";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Solar Power Plant maintenance</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Working with best brands like Adani, Waree, Havells, Polycab, Vikram Solar and other brands for all kinds of Solar systems. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/ServicesPage" />
            </Helmet>
      <Header>
      </Header>
      <MainFeature1
        subheading={<Subheading>About USPL Solar</Subheading>}
        heading="GREEN ENERGY FOR EVERYONE "
        description="We at USPL believe that Solar should be user-friendly and not so complicated. To make it hassle-free, we provide all related services in a digitized manner on your phones. Start by saying Hi on WhatsApp."
        buttonRounded={false}
        primaryButtonText="Say Hi!"
        imageSrc="https://scontent.famd8-1.fna.fbcdn.net/v/t31.18172-8/28954278_962947753830256_1652481584782718916_o.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=aUXAqQa3e74AX8MOtih&_nc_ht=scontent.famd8-1.fna&oh=cd39c9e066b0938feca0835bfd378622&oe=61405D7A"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="SAVE MONEY, SAVE THE ENVIRONMENT"
        description="We provide affordable installation, maintenance and operations services. We also help you get the most out of your system."
        buttonRounded={false}
        primaryButtonText="Enquire"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Mission</Subheading>}
        heading="We strive"
        description="To create communities that are self-reliant for their energy needs and can focus on their socio-economic growth rather than getting access to basic needs."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "We provide 24/7 support for all types of Solar Power Plants. Drop us a message to get started. "
          },
          {
            imageSrc: ShieldIconImage,
            title: "Preventative Maintenance",
            description: "We provide preventative maintenance services for all types of Solar Power Plants. Including sending maintenance reminders to you. "
          },
          {
            imageSrc: CustomizeIconImage,
            title: "Installation Services",
            description: "Whether you want to get a new plant installed or relocate an existing Solar Power Plant, USPL helps you do it seamlessly and at the comfort of your home. "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "DISCOM Approvals",
            description: "Get your Solar Power Plant approved by your DISCOM at the comfort of your home. Send us your electricity bill over WhatsApp. "
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
