import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
//import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputFormUSPL.js";
import FAQ from "components/faqs/SingleCol.js";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Solar Energy Company at reasonable prices</title>
                <meta name="description" content="Best Solar Installation Company in Udaipur, Rajasthan. Working with best brands like Adani, Waree, Havells, Polycab, Vikram Solar and other brands for all kinds of Solar systems. Over 500 happy clients. Fastest solar installation." />
                <link rel="canonical" href="https://www.usplsolar.com/components/innerPages/PricingPage" />
            </Helmet>
      <Header>
      </Header>
      <Pricing />
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
  );
};
